import './App.css';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { diffChars } from 'diff';

class LevelSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleLevelChange = this.handleLevelChange.bind(this);
  }

  handleLevelChange(event) {
    this.props.onLevelChange(event.target.value);
  }

  render() {
    let buttons = []

    // Determine Button Color and Fill
    for (let i = 1; i < 11; i++) {
      let variantname = "";
      if (i < 4) {
        variantname = "success";
      }
      else if (i < 7) {
        variantname = "warning";
      }
      else if (i < 9) {
        // Skip grouped levels 7-9
        continue;
      }
      else {
        variantname = "danger";
      }

      if (i > this.props.level) {
        variantname = `outline-${variantname}`
      }

      let label = i > 9 ? "+" : i;
      buttons.push(<Button key={i} value={i} variant={variantname} onClick={this.handleLevelChange}>{label}</Button>)
    }
    return (
      <ButtonGroup className="mb-3">{buttons}</ButtonGroup>
    )
  }
}

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      currentTime: new Date()
    }
  }

  componentDidMount() {
    const intervalId = setInterval(() => this.tick(), 50);
    this.setState({
      intervalId: intervalId
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  tick() {
    if (!this.props.stop) {
      this.setState({
        currentTime: new Date()
      });
    }
  }

  render() {
    const startTime = this.props.startTime || this.state.currentTime;
    const timeDiffMs = this.state.currentTime - startTime;
    const remMs = String(timeDiffMs % 1000).padStart(3, "0");
    const remSec = String((timeDiffMs - (timeDiffMs % 1000)) / 1000 % 60).padStart(2, "0");
    const remMin = String((timeDiffMs - (timeDiffMs % 60000)) / 60000);
    const formattedTimeDiff = `${remMin}'  ${remSec}.${remMs}"`
    return (
      <p>{formattedTimeDiff}</p>
    )
  }
}

class HighlightedDiff extends React.Component {
  render() {
    let trimmedActual = this.props.actual.replace(/[a-z]+$/, "")
    let differences = diffChars(this.props.expected, trimmedActual)
    let highlightedDiff = []
    let keyCount = 0

    // If added text is after missing text, put it before the missing text
    let followsMissingText = false;
    for (let diff of differences) {
      let key = `diff-${keyCount++}`
      if (diff.removed) {
        highlightedDiff.push(<span key={key} className="text-danger">{diff.value}</span>)
        followsMissingText = true;
      }
      else if (diff.added) {
        if (followsMissingText) {
          highlightedDiff.splice(highlightedDiff.length - 1, 0, <span key={key} className="text-warning">{diff.value}</span>);
        }
        else {
          highlightedDiff.push(<span key={key} className="text-warning">{diff.value}</span>)
        }
        followsMissingText = false;
      }
      else {
        highlightedDiff.push(<span key={key} className="text-success">{diff.value}</span>)
        followsMissingText = false;
      }
    }

    return <p id="diff">{highlightedDiff}</p>
  }
}

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.responseInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.responseInput.current.focus();
  }

  handleInputChange(event) {
    this.props.onChange(event.target.value);
  }

  handleSubmit(event) {
    this.props.onSubmit();
  }

  render() {
    return (
      <div id="question">
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Form.Control
            id="answer"
            ref={this.responseInput}
            placeholder="Enter the text above quickly!"
            onChange={this.handleInputChange}
            />
          <Button className="question" type="submit">Submit Answer</Button>
        </Form>
      </div>
    );
  }
}

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.nextQuestionButton = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.nextQuestionButton.current.focus();
  }

  handleSubmit(event) {
    this.props.onSubmit();
  }

  render() {
    return (
      <div id="results">
        <p id="pinyin">{this.props.pinyin}</p>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Show English Translation</Accordion.Header>
            <Accordion.Body>{this.props.english}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Button ref={this.nextQuestionButton} className="answer" onClick={this.handleSubmit}>Next Question</Button>
      </div>
    );
  }
}

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAnswered: false,
      hskLevel: 1,
      startTime: null,
      question: "",
      response: ""
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onResponseChange = this.onResponseChange.bind(this);
    this.onLevelChange = this.onLevelChange.bind(this);
  }

  componentDidMount() {
    this.loadNewSentence();
  }

  onSubmit() {
    if (this.state.isAnswered) {
      this.loadNewSentence();
      this.setState({
        isLoaded: false,
        isAnswered: !this.state.isAnswered,
        startTime: null,
        question: "",
        response: "",
        pinyin: "",
        english: ""
      });
    }
    else {
      this.setState({
        isAnswered: !this.state.isAnswered
      });
    }
  }

  onResponseChange(response) {
    this.setState({
      response: response
    });
  }

  onLevelChange(newLevel) {
    this.setState({
      hskLevel: newLevel
    })
  }

  loadNewSentence() {
    fetch(`/sentences/${this.state.hskLevel}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            startTime: new Date(),
            question: result["simplified"],
            response: "",
            pinyin: result["pinyin"],
            english: result["english"]
          })
        }
      );
  }

  render() {
    let contents = null;
    if (this.state.isAnswered) {
      contents = <Results pinyin={this.state.pinyin} english={this.state.english} onSubmit={this.onSubmit} />
    }
    else if (this.state.isLoaded) {
      contents = <Question onSubmit={this.onSubmit} onChange={this.onResponseChange} />
    }
    else {
      contents = <p>Loading...</p>
    }

    return (
      <div id="quiz">
        <LevelSelector level={this.state.hskLevel} onLevelChange={this.onLevelChange} />
        <Timer startTime={this.state.startTime} stop={this.state.isAnswered} />
        <p id="question">{this.state.question}</p>
        <HighlightedDiff expected={this.state.question} actual={this.state.response} />
        {contents}
      </div>
    );
  }
}

function App() {
  return (
    <div className="App d-flex flex-column">
      <Container>
          <h1>Midki Typing</h1>
          <p className="text-muted">
            Practice typing Chinese sentences. Choose the maximum <a href="https://en.wikipedia.org/wiki/Hanyu_Shuiping_Kaoshi">HSK 3.0 level</a> on the selector below...
          </p>
          <Quiz />
      </Container>
      <footer className="mt-auto py-3 bg-light border-top">
        <p>Sentences from <a href="https://resources.allsetlearning.com/chinese/grammar">AllSet Learning's Chinese Grammar Wiki</a></p>
        <p>Designed by <a href="https://www.todonnell.us">Tom O'Donnell</a></p>
      </footer>
    </div>
  );
}

export default App;
